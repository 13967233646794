
import {defineComponent, onMounted, onUpdated} from "vue";
import VisualEmbed from "@/views/quicksight/VisualEmbed.vue";

export default defineComponent({
  name: "Analytics",
  components: {VisualEmbed},
  props: {
    objectId: {},
    objectType: {},
  },
  setup() {
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      console.log(link);
      if (link) {
        link.classList.remove("active")
      }
    })
    onMounted(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
  }
})
